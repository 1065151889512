import React, { useContext, useState } from 'react'
import '../ReusableComponentsCss/CenterSearch.css'
import Search from '../../assets/Search.svg'
import AppContext from '../../store/DataProvider'
import Masonry from "react-responsive-masonry"
import Cross from '../../assets/Cross.svg'
import Loader from './Loader'
import CenterImagePreviewPopup from './CenterImagePreviewPopup'

const CenterSearch = () => {
    const context = useContext(AppContext)
    const [showPopup, setShowPopup] = useState(false)
    const [url, setUrl] = useState(null)
    const [imageData, setImageData] = useState(null)

    const scrollStarted = (e) => {
        if (e.target.scrollTop + e.target.clientHeight <= e.target.scrollHeight - 50) return
        if (context.loading || context.dataFinished) return;
        context.getTheImages(false, context.search, true);
    };

    const loadingArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]

    const searchOnEnter = (e) => {
        if (e.key === "Enter") {
            context.getTheImages(true, e.target.value, true);
        }
    }

    const openImagePreview = (data) => {
        setShowPopup(true)
        setUrl("https://d1s76hi2oiizxo.cloudfront.net/fit-in/400x0/" + data.iconName)
        setImageData(data)
    }


    return (
        <React.Fragment>
            <div className="search-container-center">
                {showPopup ? <CenterImagePreviewPopup data={imageData} url={url} close={() => setShowPopup(false)} /> : null}
                <div className="input-container">
                    <input type="text"
                        value={context.search}
                        onChange={(e) => context.getTheImages(true, e.target.value)}
                        onKeyDown={searchOnEnter}
                        placeholder="Search free elements"
                    />

                    <img className='search' src={Search} alt="" />
                    {
                        context.search !== "" ?
                            <img
                                onClick={() => context.getTheImages(true, "", true)}
                                className='cross'
                                src={Cross} alt="" /> :
                            null
                    }
                </div>
                <div className='scroll-container' onScroll={scrollStarted}>
                    <Masonry columnsCount={6} gutter='20px'>
                        {
                            context.images.map((data, index) => {
                                return <div className="card" key={data._id + index} onClick={() => openImagePreview(data)}>
                                    <img src={"https://d1s76hi2oiizxo.cloudfront.net/fit-in/400x0/" + data.iconName} alt="" />
                                </div>
                            })
                        }

                        {
                            context.loading ? loadingArray.map((data) => {
                                return <Loader key={data} />
                            }) : null
                        }
                    </Masonry>
                    {
                        context.images.length === 0 && context.loading === false ?
                            <div className="no-result">
                                <span>No elements found for this search</span>
                            </div> : null
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default CenterSearch