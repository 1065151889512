import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Home from './components/Screens/Home'
import ProtectedRoutes from './components/Screens/ProtectedRoutes'
import NotSupported from './components/Screens/NotSupported'

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRoutes exact path="/" component={Home} />
        <Route exact path="/safari-not-supported" component={NotSupported} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
