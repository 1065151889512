import React, { useContext } from 'react'
import AppContext from '../../store/DataProvider'
import '../ReusableComponentsCss/RightDashBoard.css'
import foliage from "../../assets/Foliage.svg"
import retro from "../../assets/Retro.svg"
import abstractShapes from "../../assets/abstractShapes.svg"
import blob from "../../assets/blob.svg"
import icons from "../../assets/icons.svg"
import ornateLines from "../../assets/ornateLines.svg"

const RightDashBoard = () => {
    const context = useContext(AppContext)

    const categories = [
        {
            name: "Foliage",
            tags: "Lush, verdant, vibrant, green, dense, alive",
            image: foliage
        },
        {
            name: "Retro",
            tags: "Funky, vintage, nostalgic, timeless, classic",
            image: retro
        },
        {
            name: "Abstract Shapes",
            tags: "Geometric, angular, bold, dynamic, stark",
            image: abstractShapes
        },
        {
            name: "Blobs",
            tags: "Organic, irregular, variable, bumpy, malleable",
            image: blob
        },
        {
            name: "Icons",
            tags: "Round, symbolic, outline,visual, unique",
            image: icons
        },
        {
            name: "Ornate Lines",
            tags: "Interlaced, curvilinear, intricate, detailed, elegant",
            image: ornateLines
        }
    ]

    return (
        <div className="right-dashboard">
            <div className="join-community">
                <span>Looking for a little assistance?</span>
                <a href="https://tawk.to/chat/615fde58157d100a41ab5e5d/1fhf7p2ht" target="_blank" rel="noopener noreferrer">
                    Live chat support
                </a>
                <a href="https://youtu.be/eS5tpAUEuzA" target="_blank" rel="noopener noreferrer">
                    Video Tutorial
                </a>
                <a href="https://www.facebook.com/groups/creatosaurus/" target="_blank" rel="noopener noreferrer">
                    Join our Facebook group
                </a>
            </div>
            <div className="recent-saved">
                <div className="head">
                    <span>Suggested Categories</span>
                </div>
                <div style={{ overflow: 'scroll' }}>
                    {categories.map((data, index) => {
                        return <div className="card" onClick={() => {
                            context.getTheImages(true, data.name, true)
                            context.changeLeftSideBarAciveButton(2)
                        }} key={index}>
                            <div className="info">
                                <span className="name">{data.name}</span>
                                <span className="tags">{data.tags}</span>
                            </div>
                            <div className="image">
                                <img src={data.image} alt="category" />
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default RightDashBoard
