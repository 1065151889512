import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import jwt_decode from "jwt-decode";

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}

const distructureCookie = () => {
    let value = getCookie('Xh7ERL0G');
    if (value === undefined) return window.open('https://www.creatosaurus.io/login?app=noice', "_self")
    localStorage.setItem('token', value)
    // localStorage.setItem('token', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc0FkbWluIjpmYWxzZSwidXNlck5hbWUiOiJzYXVyYWJoc29uZGUxMTEiLCJ0aW1lWm9uZSI6IkFzaWEvQ2FsY3V0dGEiLCJpZCI6IjYxMTM1MDRhZDAyYWMyNjhhMmY5YjU5NiIsInBsYW5JZCI6IjYyNDcwODdkMzdiNTUzMDc0ZmI2YjI1YyIsIm9uYm9hcmRpbmdfc3RhdHVzIjp0cnVlLCJpYXQiOjE2NzQzODk2MzMsImV4cCI6MTY3NDk5NDQzM30.HP-yWm-DryHAC7HE08QDBUOz1vHwkBjUHPEg4rQ5r8w");
}

const ProtectedRoutes = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={(props) => {
        distructureCookie()
        const token = localStorage.getItem('token')
        const decoded = jwt_decode(token);

        if (decoded.exp <= 1648016422) return window.open('https://www.creatosaurus.io/login?app=noice', "_self")
        //if token is not there redirect to login
        if (token === null) return <Redirect to={{ pathname: '/', state: { from: props.location } }} />

        //decode the jwt token and check the expiration time
        const expirationTime = decoded.exp * 1000
        if (Date.now() >= expirationTime) {
            return window.open('https://www.creatosaurus.io/login?app=noice', "_self")
        } else {
            return <Component {...props} />
        }
    }} />
}

export default ProtectedRoutes
