import React, { useContext, useState } from 'react'
import '../ScreensCss/Help.css'
import AppContext from '../../store/DataProvider'

const Settings = () => {
    const context = useContext(AppContext)
    const [activeButton, setActiveButton] = useState(1)

    return (
        <div className='settings-container'>
            <div className='setting-toggle-container'>
                <button className={activeButton === 1 ? "active" : null} onClick={() => setActiveButton(1)}>API</button>
                <button className={activeButton === 2 ? "active" : null} onClick={() => setActiveButton(2)}>Plans</button>
            </div>
            <div className='plan-details'>
                {
                    activeButton === 1 ?
                        <>
                            <h1>Noice API for Developers</h1>
                            <div className="plansParaOne">
                                <p>Easily add resources to your apps. Get up and running quickly with our diverse assets libraries like icons, vectors, illustrations, photos and more. Looking to integrate? Connect with us.</p>
                            </div>
                            <button className="upgradeBtn"
                                onClick={() => window.open("https://www.creatosaurus.io/contact")}>Contact Us</button>
                        </> :
                        <>
                            <h1>{context.userDetails?.featureFactoryData?.planId?.planName} Plan</h1>
                            <div className="plansParaOne">
                                <p>Access to 11,876+ free elements & 300+ categories</p>
                                <button>Yes</button>
                            </div>
                            <button className="upgradeBtn" style={{ width: 300 }}
                                onClick={() => window.open("https://www.creatosaurus.io/pricing")}>Upgrade to Increase Limits</button>
                        </>
                }
            </div>
        </div>
    )
}

export default Settings