import React from 'react'
import { toast } from 'react-toastify'
import "../ReusableComponentsCss/CenterLicence.css"

const CenterLicence = () => {

    const copyAttribution = () => {
        const copyText = document.getElementById('attribution').innerHTML;
        navigator.clipboard.writeText(copyText)
        toast.success('Attribution copied!')
    }
    return <div className='licence-container-center'>
        <div className="heading">
            <h1>License</h1>
        </div>
        <div className="sub-heading">
            <p>Noice is a Free & CC0 (Creative Commons) vector elements platform by Creatosaurus. Noice elements are made to be used freely. Our license reflects that.</p>
        </div>

        <div className="check-list">
            <p>✅ All elements can be <strong>downloaded</strong> and used for <strong>free</strong></p>
            <p>✅ <strong>Commercial</strong> and <strong>non-commercial</strong> purposes</p>
            <p>✅ <strong>No permission needed</strong> (though attribution is appreciated!)</p>
        </div>

        <div className="longform-container">
            <h4>Tip: How to give attribution ✏️</h4>
            <p>Even though attribution isn’t required, Noice Creators appreciate it as it provides exposure to their work and encourages them to continue sharing.</p>
            <div className="attribution-container">
                <p id='attribution'>Free element by <a href="https://www.creatosaurus.io/apps/noice">Noice</a> on <a href="https://www.creatosaurus.io/">Creatosaurus</a></p>
                <svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#808080" style={{
                    cursor: "pointer"
                }} onClick={() => {
                    copyAttribution()
                }}><path d="M19.4 20H9.6a.6.6 0 01-.6-.6V9.6a.6.6 0 01.6-.6h9.8a.6.6 0 01.6.6v9.8a.6.6 0 01-.6.6z" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15 9V4.6a.6.6 0 00-.6-.6H4.6a.6.6 0 00-.6.6v9.8a.6.6 0 00.6.6H9" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            </div>
        </div>

        
        <div className="not-premitted-heading">
            <h4>What is not permitted 👎</h4>
        </div>
        <div className="check-list">
            <p>🚫 Elements cannot be <strong>sold</strong> without significant modification.</p>
            <p>🚫 Compiling elements from Noice to replicate a similar or competing service.</p>
        </div>

        <div className="longform-container">
            <h4>Brief</h4>
            <p>
                Design stunning graphics, websites, products, and apps easily. Easily find elements that reflect your message, adjust the colours to suit your brand's identity, and quickly incorporate them into your design workflow with a few clicks.
            </p>
        </div>

        <div className="longform-container">
            <h4>Open Source</h4>
            <p>
                You are free to use the elements in any project, commercial or personal, without any attribution or costs. Just make sure not to replicate Noice, re-distribute the elements, or create integrations with it. This license truly grants you freedom!
            </p>
        </div>

        <div className="longform-container">
            <h4>Longform</h4>
            <p>
                You are granted an irrevocable, nonexclusive, worldwide license to freely download, copy, modify, distribute, perform and use elements from Noice, including for commercial purposes, without seeking permission from or giving attribution to the Creators or Noice. However, this license does not allow you to compile elements from Noice to create a similar or competing service.
            </p>
        </div>

        <div className="tagline-container" style={{
            marginTop: "20px",
            marginBottom: "20px"
        }}>
            <b>Stunning free elements that will make you say, Noice!</b>
        </div>
    </div>
}

export default CenterLicence;