import React from 'react'
import '../ScreensCss/Settings.css'

const Help = () => {
    return (
        <div className='help-center'>
            <a rel="noreferrer" href='https://tawk.to/chat/615fde58157d100a41ab5e5d/1fhf7p2ht' target="_blank">Live Chat Support</a>
            <a rel="noreferrer" href='https://wa.me/919665047289' target="_blank">WhatsApp Support +91 9665047289</a>
            <a rel="noreferrer" href='https://www.facebook.com/groups/creatosaurus/' target="_blank">Join Our Facebook Group</a>
            <a rel="noreferrer" href='https://youtu.be/eS5tpAUEuzA' target="_blank">Video Tutorial</a>
            <a rel="noreferrer" href='https://www.creatosaurus.io/contact' target="_blank">Request Feature</a>
            <a rel="noreferrer" href='https://www.creatosaurus.io/contact' target="_blank">Product Feedback</a>
            <a rel="noreferrer" href='https://www.creatosaurus.io/pricing' target="_blank">Pricing & Payments</a>
            <a rel="noreferrer" href='https://www.creatosaurus.io/contact' target="_blank">Email Contact</a>
            <a rel="noreferrer" href='https://calendly.com/malavwarke/creatosaurus' target="_blank">Book Demo</a>
        </div>
    )
}

export default Help