import React, { useContext } from 'react'
import AppContext from '../../store/DataProvider'
import '../ReusableComponentsCss/CenterContainer.css'
import Help from '../Screens/Help'
import Settings from '../Screens/Settings'
import CenterDashBoard from './CenterDashBoard'
import CenterLicence from './CenterLicence'
import CenterSearch from './CenterSearch'

const CenterContainer = () => {
    const context = useContext(AppContext)
    return (
        <div className="center-dashboard-container">
            {
                context.leftSideBarActiveButton === 1 ? <CenterDashBoard /> :
                    context.leftSideBarActiveButton === 2 ? <CenterSearch /> :
                        context.leftSideBarActiveButton === 3 ? <CenterLicence /> :
                            context.leftSideBarActiveButton === 4 ? <Help /> :
                                context.leftSideBarActiveButton === 5 ? <Settings /> : null
            }
        </div>
    )
}

export default CenterContainer
