import { createContext, useState } from 'react'
import Axios from 'axios'
import constant from '../constant';
import { toast } from 'react-toastify';

const AppContext = createContext({
    //state
    leftSideBarActiveButton: 1,
    images: [],
    loading: false,
    pageNum: 1,
    search: "",
    dataFinished: false,
    canEdit: false,
    userDetails: null,
    error: false,


    popularElements: [],
    popularElementsLoading: false,
    close: () => { },
    getPopularElements: () => { },

    //functions
    getUserInformation: () => { },
    changeLeftSideBarAciveButton: () => { },
    getTheImages: () => { },
})

export const AppContextProvider = (props) => {
    const [canEdit, setCanEdit] = useState(false)
    const [leftSideBarActiveButton, setleftSideBarActiveButton] = useState(1)
    const [images, setImages] = useState([])
    const [loading, setLoading] = useState(false)
    const [pageNum, setPageNum] = useState(1);
    const [search, setSearch] = useState("");
    const [dataFinished, setDataFinished] = useState(false)
    const [userDetails, setUserDetails] = useState(null)
    const [error, setError] = useState(false)


    const [popularElements, setPopularElements] = useState([])
    const [popularElementsLoading, setPopularElementsLoading] = useState(false)
    const [paginationNumbers, setPaginationNumbers] = useState([])

    const close = () => {
        setError(false)
    }

    const getUserInformation = async () => {
        try {
            const token = localStorage.getItem('token');
            const config = { headers: { "Authorization": `Bearer ${token}` } }

            const res = await Axios.get("https://api.app.creatosaurus.io/creatosaurus/user/info", config)

            let userDetails = res.data.activeWorkspace.team.filter(data => data.user_email === res.data.userData.email)
            if (userDetails[0].role !== "view") {
                setCanEdit(true)
            } else {
                setCanEdit(false)
            }

            setUserDetails(res.data)
            localStorage.setItem("organizationId", res.data.activeWorkspace._id)
            localStorage.setItem("organizationName", res.data.activeWorkspace.workspace_name)
            getPopularElements()
        } catch (error) {
            getPopularElements()
        }
    }

    // get initial images and load more when scroll
    const getTheImages = async (calledFromSearch, searchData, searchResult) => {
        try {
            setSearch(searchData)
            if (searchResult !== true) return

            let token = localStorage.getItem("token")
            const config = { headers: { Authorization: `Bearer ${token}` } };

            setLoading(true);
            let page = calledFromSearch ? 1 : pageNum;


            // randomize icons when the search is null code start
            let totalIcons = 5763 / 24
            if (searchData.trim() === "" && page === 1) {
                const randomOffset = Math.floor(Math.random() * totalIcons);
                setPaginationNumbers([randomOffset])
                page = randomOffset
            } else if (searchData.trim() === "" && page !== 1 && paginationNumbers.length < totalIcons) {
                let randomOffset = Math.floor(Math.random() * totalIcons);

                while (paginationNumbers.includes(randomOffset)) {
                    randomOffset = Math.floor(Math.random() * totalIcons)
                }

                setPaginationNumbers((prev) => [...prev, randomOffset])
                page = randomOffset
            } else if (searchData.trim() !== "" && page === 1) {
                setPaginationNumbers([1])
                page = 1
            }  // randomize icons when the search is null code end

            if (calledFromSearch) {
                setImages([])
                setDataFinished(false)
            }

            if (constant.cancelToken) {
                constant.cancelToken.cancel(); // Cancel the previous request before making a new request
            }

            let query = searchData
            constant.cancelToken = Axios.CancelToken.source();

            const response = await Axios.get(`${constant.url}/icon/search?query=${query}&page=${page}&per_page=24`,
                config,
                {
                    cancelToken: constant.cancelToken.token
                });


            if (response.data.length < 24) {
                setDataFinished(true)
            }

            if (calledFromSearch) {
                setPageNum(2)
            } else {
                setPageNum((prev) => prev + 1)
            }

            setLoading(false)
            setImages((prev) => [...prev, ...response.data])
        } catch (error) {
            setLoading(false);
            if (error.response.data === "Too many accounts request, please try again.") {
                setError(true)
            } else {
                toast.error(error.response.data)
            }
        }
    };

    // get pouplar images
    const getPopularElements = async () => {
        try {
            let token = localStorage.getItem("token")
            const config = { headers: { Authorization: `Bearer ${token}` } };
            if (popularElements.length !== 0) return
            setPopularElementsLoading(true)
            const res = await Axios.get(`${constant.url}/popular/icons`, config)
            setPopularElements(res.data.icons)
            setPopularElementsLoading(false)
        } catch (error) {
            setPopularElementsLoading(false)
            if (error.response.data === "Too many accounts request, please try again.") {
                setError(true)
            } else {
                toast.error(error.response.data)
            }
        }
    }

    const changeLeftSideBarAciveButton = (value) => {
        setleftSideBarActiveButton(value)
    }


    const context = {
        //state
        error: error,
        canEdit: canEdit,
        leftSideBarActiveButton: leftSideBarActiveButton,
        images: images,
        loading: loading,
        search: search,
        dataFinished: dataFinished,
        userDetails: userDetails,


        popularElements: popularElements,
        popularElementsLoading: popularElementsLoading,
        getPopularElements: getPopularElements,
        close: close,

        //functions
        changeLeftSideBarAciveButton: changeLeftSideBarAciveButton,
        getTheImages: getTheImages,
        getUserInformation: getUserInformation,
    }

    return <AppContext.Provider value={context}>
        {props.children}
    </AppContext.Provider>
}

export default AppContext