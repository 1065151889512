import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotSupported = () => {

  const copyLink = () => {
    const copyText = document.getElementById("copy");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    toast(`Link copied to clipboard`);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', textAlign: 'center', fontSize: 18, fontWeight: 500 }}>
      <div>Works best on chrome.</div>
      <div>Safari support arriving soon.</div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>Copy &nbsp;</span>
        <div style={{ display: 'flex', alignItems: 'center' }} onClick={copyLink}>
          <span style={{ color: 'red', marginRight: 5, cursor: 'pointer' }}>www.app.creatosaurus.io </span>
          <svg style={{ cursor: 'pointer' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.4 20H9.6C9.26863 20 9 19.7314 9 19.4V9.6C9 9.26863 9.26863 9 9.6 9H19.4C19.7314 9 20 9.26863 20 9.6V19.4C20 19.7314 19.7314 20 19.4 20Z" stroke="red" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15 9V4.6C15 4.26863 14.7314 4 14.4 4H4.6C4.26863 4 4 4.26863 4 4.6V14.4C4 14.7314 4.26863 15 4.6 15H9" stroke="red" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <span>&nbsp; and open in chrome</span>
        <input
          style={{ position: 'absolute', top: -1000 }}
          id="copy"
          value="www.app.creatosaurus.io"
          readOnly={true}
          type="text" />
      </div>
      <ToastContainer />
    </div>
  )
}

export default NotSupported