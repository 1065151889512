import React, { useState } from 'react'
import '../ReusableComponentsCss/CenterImagePreviewPopup.css'
import CenterPopup from './CenterPopup';
import SharePopup from './SharePopup';

const CenterImagePreviewPopup = ({ data, url, close }) => {
    const [showPopup, setShowPopup] = useState(false)
    const [sharePopupShow, setSharePopupShow] = useState(false)

    async function toDataURL(url) {
        const blob = await fetch(url).then(res => res.blob());
        return URL.createObjectURL(blob);
    }

    async function downloadImage() {
        setShowPopup(true)
        if (data.mimeType === "image/svg+xml" || data.mimeType === "svg") {
            const a = document.createElement("a");
            a.href = await toDataURL(data.URL + "?" + Date.now());
            a.download = "NoiceElements.svg";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            const a = document.createElement("a");
            a.href = await toDataURL(data.URL + "?" + Date.now());
            a.download = `NoiceElements.${data.mimeType.split("/")[1]}`
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    const createURL = () => {
        let type = ""
        if (data.mimeType === "image/svg+xml" || data.mimeType === "svg") {
            type = "svg"
        } else {
            type = data.mimeType.split("/")[1]
        }

        return `https://www.muse.creatosaurus.io/editor?url=${data.iconName}&type=${type}`
    }

    return (
        <div className='center-image-popup' onClick={close}>
            {showPopup ? <CenterPopup url={url} close={() => setShowPopup(false)} /> : null}
            {sharePopupShow ? <SharePopup close={() => setSharePopupShow(false)} /> : null}
            <div className='popup-card' onClick={(e) => e.stopPropagation()}>
                <img src={url} alt='' />
                <div className='buttons'>
                    <a href={createURL()} target='_blank' rel="noreferrer">
                        <button>
                            <div className='circle'>
                                M
                            </div>
                            Edit in Muse AI
                        </button>
                    </a>

                    <button onClick={downloadImage} style={{ backgroundColor: '#009B77', color: '#fff', border: 'none' }}>Free Download</button>
                    <button onClick={() => setSharePopupShow(true)} style={{ backgroundColor: '#D9D9D9', color: '#808080', border: 'none' }}>Share</button>
                </div>

                <svg onClick={close} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.2 16L4 14.8L8.8 10L4 5.2L5.2 4L10 8.8L14.8 4L16 5.2L11.2 10L16 14.8L14.8 16L10 11.2L5.2 16Z" fill="#C4C4C4" />
                </svg>
            </div>
        </div>
    )
}

export default CenterImagePreviewPopup