import React, { useContext } from 'react'
import Search from '../../assets/Search.svg'
import '../ReusableComponentsCss/CenterDashBoard.css'
import AppContext from '../../store/DataProvider'
import Cross from '../../assets/Cross.svg'

const CenterDashBoard = () => {
    const context = useContext(AppContext)

    const changeToSearchBar = (e) => {
        e.preventDefault()
        context.changeLeftSideBarAciveButton(2)
    }

    const categoryData = [
        {
            title: "Sports & Activities Illustrations",
            search: "sports",
            p: "Dynamic, Captivating, Inspiring, Vivid, Engaging",
            url: "https://creatosaurus-cache.s3.ap-south-1.amazonaws.com/e268ac12-c240-4cbe-8680-f0d025db3617"
        }, {
            title: "eCommerce Notion Doodle Illustrations",
            search: "ecommerce",
            p: "Innovative, Engaging, Visual, Informative, Conversions",
            url: "https://creatosaurus-cache.s3.ap-south-1.amazonaws.com/cff7dbea-3e7a-4aad-bf27-6e0641ecdb8c"
        }, {
            title: "Tiger & Snail Finance",
            search: "finance",
            p: "Friendly, Comprehensive, Trust, Visual",
            url: "https://creatosaurus-cache.s3.ap-south-1.amazonaws.com/1fdb3bd0-c414-46ae-8bf6-89fd1194932f"
        }, {
            title: "Business Buddies Illustrations",
            search: "business",
            p: "Startup, Engaging, Growth, Impactful, Professional",
            url: "https://creatosaurus-cache.s3.ap-south-1.amazonaws.com/301bf379-9efe-4bb1-8fa8-2e8ede803e78"
        },
    ]

    const searchCategory = (value) => {
        context.getTheImages(true, value, true)
        context.changeLeftSideBarAciveButton(2)
    }

    const searchOnEnter = (e) => {
        if (e.key === "Enter") {
            context.getTheImages(true, e.target.value, true);
        }
    }

    return (
        <div className="center-dashboard">
            <div className="search-container">
                <div>
                    <p>The greatest assets cannot be bought with money.</p>
                    <span>- Lailah Gifty Akita</span>
                </div>
                <div className="input-container">
                    <form onSubmit={changeToSearchBar}>
                        <input type="text"
                            value={context.search}
                            onKeyDown={searchOnEnter}
                            onChange={(e) => context.getTheImages(true, e.target.value)}
                            placeholder="Search" />
                    </form>
                    <img className='search' src={Search} alt="" />
                    {
                        context.search !== "" ?
                            <img
                                onClick={() => {
                                    context.getTheImages(true, "", true)
                                }}
                                className='cross'
                                src={Cross} alt="" /> :
                            null
                    }
                </div>
            </div>
            <div className="quotes-info">
                <div>300+ categories</div>
                <div className="line" />
                <div title="Total elements">11,876+ free elements</div>
                <div className="line" />
                <div title='time you have saved by exploring elements'>0 mins saved</div>
            </div>
            <div className="popular-quotes">
                <div className="head">
                    <span>Popular illustration packages</span>
                </div>

                <div className='card-container'>
                    {
                        categoryData.map((data, index) => {
                            return <div className='card' onClick={() => searchCategory(data.search)} key={data.title + index}>
                                <div>
                                    <h4>{data.title}</h4>
                                    <p>{data.p}</p>
                                </div>
                                <img alt='' src={data.url} />
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default CenterDashBoard
