import React from 'react'
import '../ReusableComponentsCss/CenterPopup.css'
import { toast } from 'react-toastify'

const CenterPopup = ({ close, url }) => {
    const openURL = (type) => {
        let referLink = "https://www.creatosaurus.io/apps/noice"

        if (type === "copy") {
            const textArea = document.createElement("textarea");
            textArea.value = referLink
            document.body.appendChild(textArea);
            textArea.select();

            document.execCommand("copy");
            document.body.removeChild(textArea);
            toast.info("Link Copied to Clipboard")
        } else if (type === "twitter") {
            window.open(`https://twitter.com/intent/tweet?text=${referLink}`)
        } else if (type === "linkedin") {
            window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${referLink}`)
        } else if (type === "instagram") {
            window.open("https://www.instagram.com/")
        } else if (type === "facebook") {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${referLink}`)
        } else if (type === "redit") {
            window.open(`https://www.reddit.com/submit?url=${referLink}`)
        } else if (type === "pinterest") {
            window.open(`https://pinterest.com`)
        } else if (type === "email") {
            window.open(`mailto:?body=${referLink}`)
        }
    }

    return (
        <div className='center-popup-container' onClick={close}>
            <div className='card1' onClick={(e) => e.stopPropagation()}>
                <img src={url} alt='' />
                <div className='info'>
                    <h4>Say thanks!</h4>
                    <p>Give a shoutout to <a href='https://www.noice.creatosaurus.io/'>Noice</a> on social or copy the text below to attribute.</p>
                    <div>
                        <svg onClick={() => openURL("copy")} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.357 13.5346L9.99997 15.8916C9.18483 16.7067 8.20274 17.1143 7.05369 17.1143C5.90464 17.1143 4.92255 16.7067 4.10741 15.8916C3.29227 15.0765 2.88471 14.0944 2.88471 12.9453C2.88471 11.7963 3.29227 10.8142 4.10741 9.99903L6.46443 7.64201L7.64295 8.82052L5.28592 11.1775C4.79488 11.6686 4.54935 12.2578 4.54935 12.9453C4.54935 13.6328 4.79488 14.222 5.28592 14.7131C5.77697 15.2041 6.36622 15.4496 7.05369 15.4496C7.74116 15.4496 8.33041 15.2041 8.82146 14.7131L11.1785 12.3561L12.357 13.5346ZM8.2322 12.9453L7.05369 11.7668L11.7677 7.05276L12.9462 8.23127L8.2322 12.9453ZM13.5355 12.3561L12.357 11.1775L14.714 8.82052C15.2051 8.32948 15.4506 7.74022 15.4506 7.05276C15.4506 6.36529 15.2051 5.77603 14.714 5.28499C14.223 4.79394 13.6337 4.54842 12.9462 4.54842C12.2588 4.54842 11.6695 4.79394 11.1785 5.28499L8.82146 7.64201L7.64295 6.4635L9.99997 4.10648C10.8151 3.29134 11.7972 2.88377 12.9462 2.88377C14.0953 2.88377 15.0774 3.29134 15.8925 4.10648C16.7077 4.92161 17.1152 5.90371 17.1152 7.05275C17.1152 8.2018 16.7077 9.1839 15.8925 9.99903L13.5355 12.3561Z" fill="#808080" />
                        </svg>
                        <svg onClick={() => openURL("facebook")} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.1645 17.4976V10.6676H13.4687L13.8112 7.99346H11.1645V6.29012C11.1645 5.51846 11.3795 4.99012 12.487 4.99012H13.8904V2.60596C13.2079 2.53263 12.5212 2.49762 11.8345 2.50012C9.79785 2.50012 8.39952 3.74346 8.39952 6.02596V7.98846H6.11035V10.6626H8.40452V17.4976H11.1645Z" fill="#808080" />
                        </svg>
                        <svg onClick={() => openURL("twitter")} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.3612 6.66461C16.372 6.81044 16.372 6.95544 16.372 7.10044C16.372 11.5379 12.9945 16.6513 6.82199 16.6513C4.92033 16.6513 3.15366 16.1004 1.66699 15.1438C1.93699 15.1746 2.19699 15.1854 2.47783 15.1854C4.04699 15.1854 5.49116 14.6554 6.64533 13.7513C5.16949 13.7204 3.93283 12.7538 3.50616 11.4238C3.71366 11.4546 3.92199 11.4754 4.14033 11.4754C4.44116 11.4754 4.74366 11.4338 5.02449 11.3613C3.48533 11.0496 2.33283 9.69878 2.33283 8.06711V8.02544C2.78033 8.27461 3.29949 8.43044 3.84949 8.45128C2.94533 7.84961 2.35283 6.82044 2.35283 5.65628C2.35283 5.03294 2.51866 4.46128 2.80949 3.96294C4.46199 5.99878 6.94616 7.32961 9.73116 7.47544C9.67949 7.22544 9.64783 6.96628 9.64783 6.70628C9.64783 4.85628 11.1445 3.34961 13.0045 3.34961C13.9712 3.34961 14.8437 3.75461 15.457 4.40961C16.2153 4.26378 16.942 3.98294 17.587 3.59878C17.3378 4.37794 16.807 5.03294 16.1112 5.44878C16.787 5.37544 17.442 5.18878 18.0437 4.92878C17.587 5.59378 17.0162 6.18628 16.3612 6.66461Z" fill="#808080" />
                        </svg>
                        <svg onClick={() => openURL("pinterest")} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 2C5.58882 2 2 5.58882 2 10C2 13.438 4.18003 16.3757 7.23004 17.505C7.1422 16.7219 7.15597 15.4393 7.30093 14.8183C7.43667 14.235 8.17788 11.101 8.17788 11.101C8.17788 11.101 7.95412 10.6529 7.95412 9.99108C7.95412 8.95102 8.5567 8.17502 9.30711 8.17502C9.94535 8.17502 10.2531 8.65419 10.2531 9.2283C10.2531 9.87029 9.84496 10.8292 9.63356 11.718C9.45724 12.4627 10.0069 13.0698 10.7409 13.0698C12.07 13.0698 13.0917 11.6682 13.0917 9.64536C13.0917 7.8549 11.8054 6.60286 9.96837 6.60286C7.8408 6.60286 6.59192 8.19888 6.59192 9.84842C6.59192 10.491 6.83929 11.1801 7.14848 11.5552C7.20945 11.6294 7.21837 11.6941 7.20026 11.7695C7.1436 12.0056 7.01762 12.5136 6.99259 12.6177C6.96009 12.7549 6.88415 12.7834 6.74263 12.7175C5.80845 12.2826 5.22455 10.9172 5.22455 9.82023C5.22455 7.46143 6.93822 5.29473 10.166 5.29473C12.7603 5.29473 14.7763 7.14302 14.7763 9.614C14.7763 12.1914 13.1509 14.2655 10.8957 14.2655C10.1378 14.2655 9.42561 13.8717 9.182 13.4066C9.182 13.4066 8.80694 14.8344 8.71576 15.1844C8.55838 15.7898 7.8847 17.0439 7.54651 17.6136C8.32016 17.8635 9.14428 18 10 18C14.4113 18 18 14.4113 18 10C18 5.58882 14.4113 2 10 2Z" fill="#808080" />
                        </svg>
                        <svg onClick={() => openURL("instagram")} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4554 6.92104C17.4462 6.29021 17.3296 5.66438 17.1062 5.07438C16.7154 4.06688 15.9196 3.27021 14.9121 2.88021C14.3296 2.66104 13.7137 2.54354 13.0904 2.53021C12.2887 2.49438 12.0346 2.48438 9.99957 2.48438C7.96457 2.48438 7.70374 2.48438 6.9079 2.53021C6.2854 2.54354 5.66957 2.66104 5.08707 2.88021C4.07957 3.27021 3.2829 4.06688 2.8929 5.07438C2.67374 5.65688 2.5554 6.27271 2.54374 6.89521C2.5079 7.69771 2.49707 7.95188 2.49707 9.98688C2.49707 12.0219 2.49707 12.2819 2.54374 13.0785C2.55624 13.7019 2.67374 14.3169 2.8929 14.901C3.28374 15.9077 4.07957 16.7044 5.0879 17.0944C5.6679 17.321 6.28374 17.4494 6.90874 17.4694C7.71124 17.5052 7.9654 17.516 10.0004 17.516C12.0354 17.516 12.2962 17.516 13.0921 17.4694C13.7146 17.4569 14.3304 17.3394 14.9137 17.1202C15.9212 16.7294 16.7171 15.9327 17.1079 14.926C17.3271 14.3427 17.4446 13.7277 17.4571 13.1035C17.4929 12.3019 17.5037 12.0477 17.5037 10.0119C17.5021 7.97687 17.5021 7.71854 17.4554 6.92104ZM9.99457 13.8352C7.86624 13.8352 6.14207 12.111 6.14207 9.98271C6.14207 7.85437 7.86624 6.13021 9.99457 6.13021C12.1212 6.13021 13.8471 7.85437 13.8471 9.98271C13.8471 12.111 12.1212 13.8352 9.99457 13.8352ZM14.0004 6.88604C13.5029 6.88604 13.1021 6.48438 13.1021 5.98771C13.1021 5.49104 13.5029 5.08937 14.0004 5.08937C14.4962 5.08937 14.8979 5.49104 14.8979 5.98771C14.8979 6.48438 14.4962 6.88604 14.0004 6.88604Z" fill="#808080" />
                            <path d="M9.99469 12.4855C11.3768 12.4855 12.4972 11.3651 12.4972 9.98297C12.4972 8.60088 11.3768 7.48047 9.99469 7.48047C8.6126 7.48047 7.49219 8.60088 7.49219 9.98297C7.49219 11.3651 8.6126 12.4855 9.99469 12.4855Z" fill="#808080" />
                        </svg>
                        <svg onClick={() => openURL("linkedin")} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.15244 5.99823C5.15943 5.99823 5.97577 5.18189 5.97577 4.1749C5.97577 3.1679 5.15943 2.35156 4.15244 2.35156C3.14544 2.35156 2.3291 3.1679 2.3291 4.1749C2.3291 5.18189 3.14544 5.99823 4.15244 5.99823Z" fill="#808080" />
                            <path d="M7.69744 7.37891V17.4947H10.8383V12.4922C10.8383 11.1722 11.0866 9.89391 12.7233 9.89391C14.3374 9.89391 14.3574 11.4031 14.3574 12.5756V17.4956H17.4999V11.9481C17.4999 9.22307 16.9133 7.12891 13.7283 7.12891C12.1991 7.12891 11.1741 7.96807 10.7549 8.76224H10.7124V7.37891H7.69744ZM2.5791 7.37891H5.72493V17.4947H2.5791V7.37891Z" fill="#808080" />
                        </svg>
                        <svg onClick={() => openURL("email")} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.33366 16.6673C2.87533 16.6673 2.48296 16.5041 2.15658 16.1777C1.83019 15.8513 1.66699 15.459 1.66699 15.0007V5.00065C1.66699 4.54232 1.83019 4.14996 2.15658 3.82357C2.48296 3.49718 2.87533 3.33398 3.33366 3.33398H16.667C17.1253 3.33398 17.5177 3.49718 17.8441 3.82357C18.1705 4.14996 18.3337 4.54232 18.3337 5.00065V15.0007C18.3337 15.459 18.1705 15.8513 17.8441 16.1777C17.5177 16.5041 17.1253 16.6673 16.667 16.6673H3.33366ZM10.0003 10.834L3.33366 6.66732V15.0007H16.667V6.66732L10.0003 10.834ZM10.0003 9.16732L16.667 5.00065H3.33366L10.0003 9.16732ZM3.33366 6.66732V5.00065V15.0007V6.66732Z" fill="#808080" />
                        </svg>
                    </div>

                    <div className='linkes-info'>
                        <span>Free Illustration by <a href='https://www.noice.creatosaurus.io/'>Noice</a> on <a href='https://creatosaurus.io/'>Creatosaurus</a></span>
                        <svg onClick={()=> openURL("copy")} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.2 14.2C7.815 14.2 7.48542 14.0629 7.21125 13.7887C6.93708 13.5146 6.8 13.185 6.8 12.8V4.4C6.8 4.015 6.93708 3.68542 7.21125 3.41125C7.48542 3.13708 7.815 3 8.2 3H14.5C14.885 3 15.2146 3.13708 15.4888 3.41125C15.7629 3.68542 15.9 4.015 15.9 4.4V12.8C15.9 13.185 15.7629 13.5146 15.4888 13.7887C15.2146 14.0629 14.885 14.2 14.5 14.2H8.2ZM8.2 12.8H14.5V4.4H8.2V12.8ZM5.4 17C5.015 17 4.68542 16.8629 4.41125 16.5887C4.13708 16.3146 4 15.985 4 15.6V5.8H5.4V15.6H13.1V17H5.4Z" fill="#808080" />
                        </svg>
                    </div>
                </div>

                <svg onClick={close} className='cross' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.2 16L4 14.8L8.8 10L4 5.2L5.2 4L10 8.8L14.8 4L16 5.2L11.2 10L16 14.8L14.8 16L10 11.2L5.2 16Z" fill="#C4C4C4" />
                </svg>
            </div>
        </div>
    )
}

export default CenterPopup