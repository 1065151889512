import React, { useContext, useState } from 'react'
import '../ReusableComponentsCss/LeftSideBar.css'
import AppContext from '../../store/DataProvider'
import Ad from '../../assets/ad.jpg'
import Ad2 from '../../assets/ad2.jpg'

const LeftSideBar = () => {
    const context = useContext(AppContext)
    const [mouseIn, setMouseIn] = useState(false)

    const changeTheContainer = (number) => {
        context.changeLeftSideBarAciveButton(number)
    }

    return (
        <div className="left-side-bar">
            <div className='buttons'>
                <div className={context.leftSideBarActiveButton === 1 ? "button-container active" : "button-container"}
                    onClick={() => changeTheContainer(1)}>
                    <span className="emoji rotate">👀</span>
                    <span className="title">Dashboard</span>
                </div>
                <div className={context.leftSideBarActiveButton === 2 ? "button-container active" : "button-container"}
                    onClick={() => changeTheContainer(2)}>
                    <span className="emoji">📝</span>
                    <span className="title">Search</span>
                </div>
                <div className={context.leftSideBarActiveButton === 3 ? "button-container active" : "button-container"}
                    onClick={() => changeTheContainer(3)}>
                    <span className="emoji">©️</span>
                    <span className="title">License</span>
                </div>
                <div className={context.leftSideBarActiveButton === 4 ? "button-container active" : "button-container"}
                    onClick={() => changeTheContainer(4)}>
                    <span className="emoji">💁🏻</span>
                    <span className="title">Help</span>
                </div>
                <div className={context.leftSideBarActiveButton === 5 ? "button-container active" : "button-container"}
                    onClick={() => changeTheContainer(5)}>
                    <span className="emoji">⚙️</span>
                    <span className="title">Settings & API</span>
                </div>
            </div>
            <img
                onMouseOut={() => setMouseIn(false)}
                onMouseEnter={() => setMouseIn(true)}
                src={mouseIn ? Ad2 : Ad}
                alt=''
                onClick={() => window.open("https://www.muse.creatosaurus.io/")} />
        </div>
    )
}

export default LeftSideBar
