import React, { useContext } from 'react'
import '../ReusableComponentsCss/CenterPopupError.css'
import AppContext from '../../store/DataProvider'

const CenterPopupError = () => {
    const context = useContext(AppContext)

    return (
        <div className='center-popup-error' onClick={() => context.close()}>
            <div className='card' onClick={(e) => e.stopPropagation()}>
                <p>Hey there! We appreciate your enthusiasm, but it seems like you've been making a
                    lot of searches. To ensure the best experience for all users, Please try again in
                    a little while. Thank you for your understanding!</p>
                <button onClick={() => context.close()}>Close</button>
            </div>
        </div>
    )
}

export default CenterPopupError