import React, { useContext, useEffect } from 'react'
import '../ScreensCss/Home.css'
import CenterContainer from '../ReusableComponents/CenterContainer'
import LeftSideBar from '../ReusableComponents/LeftSideBar'
import NavigationBar from '../ReusableComponents/NavigationBar'
import RightContainer from '../ReusableComponents/RightContainer'
import AppContext from '../../store/DataProvider'
import CenterPopupError from '../ReusableComponents/CenterPopupError'

const Home = () => {

    const context = useContext(AppContext)

    useEffect(() => {
        context.getUserInformation()
        context.getTheImages(true, "", true)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            {context.error ? <CenterPopupError /> : null}
            
            <NavigationBar />
            {
                context.leftSideBarActiveButton === 2 ?
                    <main className="search-main">
                        <LeftSideBar />
                        <CenterContainer />
                    </main> :
                    <main>
                        <LeftSideBar />
                        <CenterContainer />
                        <RightContainer />
                    </main>
            }
        </React.Fragment>
    )
}

export default Home
