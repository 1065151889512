import React, { useContext } from 'react'
import AppContext from '../../store/DataProvider'
import '../ReusableComponentsCss/RightContainer.css'
import RightDashBoard from './RightDashBoard'


const RightContainer = () => {
    const context = useContext(AppContext)
    return (
        <div className="right-container">
            {
                context.leftSideBarActiveButton === 1 ? <RightDashBoard /> :
                    context.leftSideBarActiveButton === 3 ? <RightDashBoard /> :
                        context.leftSideBarActiveButton === 4 ? <RightDashBoard /> :
                            context.leftSideBarActiveButton === 5 ? <RightDashBoard /> : null
            }
        </div>
    )
}

export default RightContainer
